import React, { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";
import PageLoader from "./components/common/PageLoader";
import ErrorBoundary from "./layout/ErrorBoundary";

import TrackingInitializer from "./utils/TrackingInitializer";
import ROUTES from "./utils/routes";

const CampaignsTypeSelector = lazy(() => import("./pages/smb/CampaignsTypeSelector"));
const ErrorUnauthorized = lazy(() => import("./pages/errors/unauthorized"));

const routes = [
  {
    element: <ProtectedRoute />,
    errorElement: <ErrorUnauthorized />,
    children: [{
      element: <TrackingInitializer />,
      children: [
        { path: ROUTES.root, element: <CampaignsTypeSelector /> },
      ]
    }],
  }
];

const Router = () => (
  <ErrorBoundary>
    <Suspense fallback={<PageLoader fullscreen />}>
      <RouterProvider
        router={createBrowserRouter(routes)}
        fallbackElement={<PageLoader fullscreen />}
      />
    </Suspense>
  </ErrorBoundary >
);

export default Router;
