import React from "react";

import Button from "clever-react/ui/Button";
import Grid from "clever-react/ui/Grid";

import makeStyles from "clever-react/ui/styles/makeStyles";
import Warning from "clever-react/ui/Icon/Warning";
import GeneralLogo from "components/common/GeneralLogo";

import Translate from "components/common/Translate";
import LocalImage from "components/common/LocalImage";

import ROUTES from "utils/routes";

const getClasses = makeStyles(({ cleverUI: { spacing, breakpoints } }) => ({
  root: { ...spacing["mb-6"], textAlign: "center" },
  logo: { ...spacing["mt-8"], ...spacing["mb-6"], maxHeight: "70px", [breakpoints.down("sm")]: { width: "100%" } },
  image: { maxHeight: "100px", width: "130px" },
  imageContainer: { height: "100px", display: "flex", justifyContent: "center" },
  images: { ...spacing["mt-3"] },
  buttonInstall: { ...spacing["my-3"] },
  platforms: { ...spacing["mt-6"] },
  platform: { ...spacing["h-100"] },
  icon: { ...spacing["mr-2"], ...spacing["mb-0"], verticalAlign: "middle", alignSelf: "center" },
  alert: { ...spacing["mb-4"], display: "inline-flex", flexWrap: "nowrap", justifyContent: "center" },
}));

const ErrorPage = () => {
  const classes = getClasses();

  const shopType = "shop";

  return (
    <Grid container justify="center" alignItems="center" className={classes.root}>
      <Grid item xs={10}>
        <GeneralLogo type={shopType} className={classes.logo} />
      </Grid>

      <Grid item xs={11} md={10} lg={9}>
        <Translate
          code="errors.401.title"
          variant="h1"
          gutterBottom
          align="center"
          weight="bold"
          shade={900}
        />
      </Grid>

      <Grid item xs={11} md={10} lg={9}>
        <Grid container alignItems="center" className={classes.alert}>
          <Grid item>
            <Warning color="warning" className={classes.icon} />
          </Grid>

          <Grid item>
            <Translate code="errors.401.description" variant="body3" align="left" shade={900} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={11} md={10} lg={9}>
        <Translate
          code="errors.401.subtitle"
          variant="body3"
          gutterBottom
          align="center"
          shade={900}
        />
      </Grid>

      <Grid item xs={11} md={10} lg={9} className={classes.platforms}>
        <Grid container justify="space-evenly" alignItems="center" className={classes.images} spacing>
          <Grid item xs={12} sm={6} md={4} lg={2} className={classes.platform}>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12}>
                <div className={classes.imageContainer}>
                  <LocalImage src="common/logo-shopify.svg" className={classes.image} size="auto" />
                </div>
              </Grid>

              <Grid item>
                <Button href={ROUTES.installShopify} className={classes.buttonInstall}>
                  <Translate code="errors.401.button.shopify" />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} className={classes.platform}>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12}>
                <div className={classes.imageContainer}>
                  <LocalImage src="common/logo-bigcommerce.svg" className={classes.image} size="auto" />
                </div>
              </Grid>

              <Grid item>
                <Button href={ROUTES.installBigcommerce} className={classes.buttonInstall}>
                  <Translate code="errors.401.button.bigcommerce" />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} className={classes.platform}>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12}>
                <div className={classes.imageContainer}>
                  <LocalImage src="common/logo-prestashop.svg" className={classes.image} size="auto" />
                </div>
              </Grid>

              <Grid item>
                <Button href={ROUTES.installPrestashop} className={classes.buttonInstall}>
                  <Translate code="errors.401.button.prestashop" />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} className={classes.platform}>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12}>
                <div className={classes.imageContainer}>
                  <LocalImage src="common/logo-woo.svg" className={classes.image} size="auto" />
                </div>
              </Grid>

              <Grid item>
                <Button href={ROUTES.installWoocommerce} className={classes.buttonInstall}>
                  <Translate code="errors.401.button.woocommerce" />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={2} className={classes.platform}>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12}>
                <div className={classes.imageContainer}>
                  <LocalImage src="common/logo-epages.svg" className={classes.image} size="auto" />
                </div>
              </Grid>

              <Grid item>
                <Button href={ROUTES.installEpages} className={classes.buttonInstall}>
                  <Translate code="errors.401.button.epages" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
