import React from "react";

import PropTypes from "prop-types";

import Image from "clever-react/ui/Image";

/* IMPORTANT */

// This is a wrapper for local imaged located at the folder /public/img

/* IMPORTANT */

// eslint-disable-next-line object-curly-newline
const LocalImage = ({ src, alt, className, variant, size, style, center }) => (
  <Image
    alt={alt}
    src={`/assets/img/${src}`}
    className={className}
    variant={variant}
    size={size.toString()}
    style={style}
    center={center}
  />
);

LocalImage.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(["default", "circle"]),
  style: PropTypes.instanceOf(Object),
  center: PropTypes.bool,
};

LocalImage.defaultProps = {
  size: "responsive",
  variant: "default",
  className: "",
  alt: "",
  style: {},
  center: false,
};

export default LocalImage;
