import React, { useEffect, useCallback } from "react";
import { Outlet } from "react-router-dom";
import decodeJWT from "jwt-decode";

import AnalyticsProviderUA from "clever-react/ui/Provider/extensions/analytics";
import AnalyticsProviderGA4 from "clever-react/ui/Provider/extensions/analytics4";
import { setUserProperty } from "clever-react/utils/tracking/analytics4";
import { getAuthToken } from "clever-react/utils/auth";

import { getAnalyticsInfo } from "api/smb/status";
import { analyticsConfig } from "./environment";

const AnalyticsInitializer = () => {
  const { shop_id: shopId } = decodeJWT(getAuthToken());

  const setProperties = useCallback(async () => {
    const info = await getAnalyticsInfo();

    if (info && !info.error) {
      setUserProperty({
        provider: info.origin,
        suspicious: info.showTrialError
      });
    }
  }, []);

  useEffect(() => {
    setProperties();
  }, [setProperties]);


  return (
    <AnalyticsProviderUA analyticsId={analyticsConfig.uaId} sandbox={analyticsConfig.isSandbox}>
      <AnalyticsProviderGA4
        analytics4Id={analyticsConfig.ga4Id}
        isSandbox={analyticsConfig.isSandbox}
        userId={shopId}
      >
        <Outlet />
      </AnalyticsProviderGA4>
    </AnalyticsProviderUA>
  );
};

export default AnalyticsInitializer;
