export default ({
  root: "/",
  steps: "/steps",
  createShop: "/create_shop",

  googleAuth: "/google_auth",
  microsoftAuth: "/microsoft/redirect",
  shoppingAuth: "/shopping_auth",

  404: "/404",

  // External links
  installShopify: "https://apps.shopify.com/cleverppc",
  installPrestashop: "https://addons.prestashop.com/es/posicionamiento-patrocinado-afiliacion/31956-clever-google-ads.html",
  installBigcommerce: "https://www.bigcommerce.com/apps/google-clever-adw/",
  installWoocommerce: "https://wordpress.org/plugins/woo-clever-google-ads/",
  installEpages: "https://epages.com/",

  privacyPolicy: "https://cleverads.com/privacy-policy",
  tos: "https://cleverads.com/terms-conditions",

  blog: {
    shop: "http://www.cleverecommerce.com/blog/",
    business: "http://cleverads.com/blog/",
    landing: "http://www.cleverecommerce.com/blog/",
  },

  // RRSS
  linkedin: {
    shop: "https://www.linkedin.com/company/clever-ecommerce/?originalSubdomain=es",
    business: "https://www.linkedin.com/company/clever-ads",
    landing: "https://www.linkedin.com/company/clever-ecommerce/?originalSubdomain=es",
  },
  instagram: {
    shop: "https://www.instagram.com/clever_ecommerce/",
    business: "https://www.instagram.com/AdsClever/",
    landing: "https://www.instagram.com/clever_ecommerce/",
  },
  twitter: {
    shop: "https://twitter.com/cleverecommerce",
    business: "https://twitter.com/AdsClever",
    landing: "https://twitter.com/cleverecommerce",
  },
  facebook: {
    shop: "https://www.facebook.com/cleverecommerce/",
    business: "https://www.facebook.com/Clever-Ads-109439973846222/",
    landing: "https://www.facebook.com/cleverecommerce/",
  },
  pinterest: {
    shop: "https://www.pinterest.es/cleverecommerce/",
    business: "https://www.pinterest.es/clever_ads/",
    landing: "https://www.pinterest.es/cleverecommerce/",
  },
  youtube: {
    shop: "https://www.youtube.com/channel/UCqafcIaWw93hF-ZvPrJLMnQ",
    business: "https://www.youtube.com/channel/UCsQt6FzS7kWmOe8872pNTfw",
    landing: "https://www.youtube.com/channel/UCqafcIaWw93hF-ZvPrJLMnQ",
  },
  productHunt: {
    shop: "", // Cleverecommerce doesn't have product hunt
    business: "https://www.producthunt.com/posts/google-ads-creator-by-clever-ads",
    landing: "",
  },
});
