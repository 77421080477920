export const API_URL = process.env.REACT_APP_API_URL;
export const SMB_API_URL = process.env.REACT_APP_SMB_API_URL;
export const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL;
export const STEPS_URL = process.env.REACT_APP_STEPS_URL;
export const CREATE_SHOP_URL = process.env.REACT_APP_CREATE_SHOP_URL;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

export const analyticsConfig = {
  uaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  ga4Id: process.env.REACT_APP_GOOGLE_ANALYTICS_G4_ID,
  isSandbox: process.env.REACT_APP_GOOGLE_ANALYTICS_SANDBOX === "true",
};

export const tagManagerConfig = {
  id: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  isSandbox: process.env.REACT_APP_GOOGLE_TAG_MANAGER_SANDBOX === "true",
};

export const freshchatConfig = { token: process.env.REACT_APP_FRESHCHAT_TOKEN };
