import React from "react";
import classnames from "classnames";
import makeStyles from "clever-react/ui/styles/makeStyles";
import LoadingClever from "clever-react/ui/LoadingClever";

const getClasses = makeStyles(() => ({
  backdrop: {
    width: "100%",
    height: "100%",
    display: "grid",
    placeItems: "center",
  },
  fullscreen: {
    width: "100vw",
    height: "100vh",
  }
}));

const PageLoader = ({ fullscreen = false }) => {
  const classes = getClasses();

  return (
    <div role="status" className={classnames(classes.backdrop, { [classes.fullscreen]: fullscreen })} aria-label="loading">
      <LoadingClever size={100} />
    </div>
  );
};

export default PageLoader;
