/* eslint-disable max-len */
import React from "react";

import PropTypes from "prop-types";

import LocalImage from "./LocalImage";

const GeneralLogo = props => {
  const { className, center, origin} = props;

  if (origin === "emonsite") {
    return (
      <LocalImage src="/common/logo-emonsite-large.png" className={className} alt="Emonsite Logo" center={center} size="auto" />
    );
  }

  if (origin === "anfix") {
    return (
      <LocalImage src="/common/logo-anfix-large.png" className={className} alt="anfix Logo" center={center} size="auto" />
    );
  }

  if (origin === "gig") {
    return (
      <LocalImage src="/common/logo-alpha.webp" className={className} alt="Alpha Shops Logo" center={center} size="auto" />
    );
  }

  if (origin === "chatwith") {
    return (
      <LocalImage src="/common/logo-chatwith-large.jpeg" className={className} alt="chatwith Logo" center={center} size="auto" />
    );
  }

  if (origin === "telefonica") {
    return (
      <LocalImage src="/common/logo-telefonica-large.svg" className={className} alt="telefonica Logo" center={center} size="auto" />
    );
  }

  if (origin === "hostalia") {
    return (
      <LocalImage src="/common/logo-hostalia-large.svg" className={className} alt="hostalia Logo" center={center} size="auto" />
    );
  }

  if (origin === "acens") {
    return (
      <LocalImage src="/common/logo-acens-large.svg" className={className} alt="acens Logo" center={center} size="auto" />
    );
  }

  if (origin === "movistar") {
    return (
      <LocalImage src="/common/logo-movistar-large.svg" className={className} alt="movistar Logo" center={center} size="auto" />
    );
  }

  if (origin === "tu_publicidad_gestionada") {
    return (
      <LocalImage src="/common/logo-tu_publicidad_gestionada-large.png" className={className} alt="tu publicidad gestionada Logo" center={center} size="auto" />
    );
  }


  return (
    <LocalImage
      src="/common/logo-cleverads_by_aleph.svg"
      className={className}
      alt="CleverAds Logo"
      center={center}
      size="auto"
    />
  );
};

GeneralLogo.propTypes = {
  origin: PropTypes.string,
  className: PropTypes.string,
};

GeneralLogo.defaultProps = {
  className: "",
  origin: "",
};

export default (GeneralLogo);
