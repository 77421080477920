/* eslint-disable no-undef */
import reduce from "lodash-es/reduce";
import { getAuthToken, hasAuthToken } from "clever-react/utils/auth";

import { API_URL, SMB_API_URL } from "utils/environment";
import { resolveCamelCaseObject, resolveSnakeCaseObject } from "./helpers";

function getAuthConfig() {
  if (hasAuthToken()) {
    return { Authorization: getAuthToken() };
  }

  return {};
}

function getBaseHeaders() {
  return {
    ...getAuthConfig(),
    "Content-Type": "application/json"
  };
}

function log(response) {
  let logger = console.log;

  try {
    if (Rollbar !== undefined && Rollbar !== null) {
      logger = message => { Rollbar.log("error", message); };
    }
  } catch (e) {
    logger = console.log;
  } finally {
    logger(`Error in request ${response.url} with status ${response.status}`);
  }
}

export function buildQueryParams(query) {
  return reduce(Object.keys(query), (buildQuery, key) => {
    if (query[key] === undefined || query[key] === null) return buildQuery;

    let res = buildQuery;

    if (res.length > 0) res += "&";

    return `${res}${key}=${query[key]}`;
  }, "");
}

function buildUrl(url, smb) {
  return `${smb ? SMB_API_URL : API_URL}${url}`;
}

function parseResponse(response) {
  try {
    if (!!response.status && response.status >= 600) {
      log(response);

      return { error: "Clever error", status: response.status };
    }
    if (response.status === 401 || response.status === 403) {
      log(response);

      return response.json()
        .then(payload => ({ error: "Unauthorized", status: 401, payload }))
        .catch(() => ({ error: "Unauthorized", status: 401 }));
    }
    if (response.status !== 200) {
      log(response);

      return { error: "Error in response", status: 500 };
    }
    return response.json().catch(() => {
      log(response);

      return ({ error: "Error parse response", status: 500 });
    });
  } catch (e) {
    log(response);

    return { error: "Error in response", status: 500 };
  }
}

const get = async (url, query) => {
  const queryParams = buildQueryParams(resolveSnakeCaseObject(query));
  let requestUrl = url;

  if (queryParams.length > 0) requestUrl += `?${queryParams}`;

  const response = await fetch(requestUrl, {
    method: "get",
    headers: new Headers(getBaseHeaders()),
  });

  return parseResponse(response);
};

const post = async (url, object) => {
  const response = await fetch(url, {
    method: "post",
    headers: new Headers(getBaseHeaders()),
    body: JSON.stringify(resolveSnakeCaseObject(object)),
  });

  return parseResponse(response);
};

const put = async (url, object) => {
  const response = await fetch(url, {
    method: "put",
    headers: new Headers(getBaseHeaders()),
    body: JSON.stringify(resolveSnakeCaseObject(object)),
  });

  return parseResponse(response);
};

const deleteRequest = async (url, query) => {
  const queryParams = buildQueryParams(resolveSnakeCaseObject(query));
  let requestUrl = url;

  if (queryParams.length > 0) requestUrl += `?${queryParams}`;

  const response = await fetch(requestUrl, {
    method: "delete",
    headers: new Headers(getBaseHeaders()),
  });

  return parseResponse(response);
};

const requestBuilder = request => async (url, data = {}, { parseToCamel = true, smb = false } = {}) => {
  const response = await request(buildUrl(url, smb), data);

  if (parseToCamel) return resolveCamelCaseObject(response);
  return response;
};

export const postHtml = async (url, object) => {
  const response = await fetch(url, {
    method: "post",
    headers: new Headers(getBaseHeaders()),
    body: JSON.stringify(object),
  });

  return response.text();
};

export const fetchJson = requestBuilder(get);
export const postJson = requestBuilder(post);
export const putJson = requestBuilder(put);
export const deleteJson = requestBuilder(deleteRequest);

export const objectToCamelCase = resolveCamelCaseObject;
