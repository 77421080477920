import React from "react";
import { SnackbarProvider as NotistackProvider } from "notistack";

import CleverUIProvider from "clever-react/ui/Provider";
import ErrorHandler from "clever-react/ui/Provider/extensions/errorHandler";
import ReduxProvider from "clever-react/ui/Provider/extensions/redux";
import { withCookies } from "clever-react/utils/cookies";

import { IntlProviderWrapper } from "./components/common/IntlContext";
import ProgressSnackbar from "./components/ProgressSnackbar";

import Error500 from "./pages/errors/error";
import ErrorNoAuth from "./pages/errors/unauthorized";

import RollbarProvider from "./RollbarProvider";

import AsyncRouter from "./router";

const App = ({ cookies }) => {
  window.dataLayer = window.dataLayer || [];
  return (
    <IntlProviderWrapper language={cookies.get("clever_ads_language")}>
      <ReduxProvider>
        <CleverUIProvider>
          <NotistackProvider
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            content={ProgressSnackbar}
          >
            <RollbarProvider errorPage={Error500}>
              <ErrorHandler
                pageErrors={{
                  500: Error500,
                  502: Error500,
                  401: ErrorNoAuth,
                  403: ErrorNoAuth,
                }}
              >
                <AsyncRouter />
              </ErrorHandler>
            </RollbarProvider>
          </NotistackProvider>
        </CleverUIProvider>
      </ReduxProvider>
    </IntlProviderWrapper>
  );
};

export default withCookies(App);
