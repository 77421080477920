import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import queryString from "query-string";

import esTranslation from "translations/es.json";
import enTranslation from "translations/en.json";
import frTranslation from "translations/fr.json";

const Context = React.createContext();
const IntlProviderWrapper = ({ children, language }) => {
  const getTranslations = lang => {
    const urlLang = queryString.parse(window.location.search).lang;
    if (urlLang) {
      switch (urlLang) {
        case "en":
          return enTranslation;
        case "es":
          return esTranslation;
        case "fr":
          return frTranslation;
        default:
          return enTranslation;
      }
    }

    switch (lang) {
      case "en":
        return enTranslation;
      case "es":
        return esTranslation;
      case "fr":
        return frTranslation;
      default:
        return enTranslation;
    }
  };
  const availableLanguages = ["es", "en", "fr"];
  const languageNav = navigator.language.split(/[-_]/)[0];
  const defaultLanguage = availableLanguages.includes(languageNav) ? languageNav : "en";

  const [locale, setLocale] = useState(language || defaultLanguage);
  const [messages, setMessages] = useState(getTranslations(locale));

  const changeLocale = newLocale => {
    switch (newLocale) {
      case "es":
        setLocale("es");
        setMessages(esTranslation);
        break;
      case "fr":
        setLocale("fr");
        setMessages(frTranslation);
        break;
      default:
        setLocale("en");
        setMessages(enTranslation);
        break;
    }
  };

  const switchToEnglish = () => {
    setLocale("en");
    setMessages(enTranslation);
  };

  const switchToSpanish = () => {
    setLocale("es");
    setMessages(esTranslation);
  };

  const switchToFrench = () => {
    setLocale("fr");
    setMessages(frTranslation);
  };

  // const language = navigator.language.split(/[-_]/)[0];
  return (
    <Context.Provider value={{ locale, messages, switchToEnglish, switchToSpanish, switchToFrench, changeLocale }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};
export { IntlProviderWrapper, Context as IntlContext };
