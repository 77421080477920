import camelCase from "lodash-es/camelCase";
import snakeCase from "lodash-es/snakeCase";

export const isObject = (o => o instanceof Object && o.constructor === Object);

export const resolveCamelCaseObject = (object = {}) => {
  if (isObject(object)) {
    const newObject = {};

    Object.keys(object).forEach(key => {
      newObject[camelCase(key)] = resolveCamelCaseObject(object[key]);
    });

    return newObject;
  }
  if (object instanceof Array) {
    return object.map(value => resolveCamelCaseObject(value));
  }

  return object;
};

export const resolveSnakeCaseObject = (object = {}) => {
  if (isObject(object)) {
    const newObject = {};

    Object.keys(object).forEach(key => {
      newObject[snakeCase(key)] = resolveSnakeCaseObject(object[key]);
    });

    return newObject;
  } if (object instanceof Array) {
    return object.map(value => resolveSnakeCaseObject(value));
  }

  return object;
};
