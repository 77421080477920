import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import includes from "lodash-es/includes";

import Typography from "clever-react/ui/Typography";

const Translate = props => {
  const {
    variant,
    gutterBottom,
    className,
    align,
    color,
    shade,
    code,
    weight,
    params,
  } = props;

  const containsHtml = includes(code, "_html");

  if (variant) {
    if (containsHtml) {
      return (
        <Typography
          variant={variant}
          gutterBottom={gutterBottom}
          className={className}
          align={align}
          color={color}
          weight={weight}
          shade={shade}
        >
          <FormattedHTMLMessage id={code} values={params} />
        </Typography>
      );
    }

    return (
      <Typography
        variant={variant}
        gutterBottom={gutterBottom}
        className={className}
        align={align}
        color={color}
        weight={weight}
        shade={shade}
      >
        <FormattedMessage id={code} values={params} />
      </Typography>
    );
  }

  if (containsHtml) return <FormattedHTMLMessage id={code} values={params} />;

  return <FormattedMessage id={code} values={params} />;
};

Translate.propTypes = {
  code: PropTypes.string.isRequired,
  variant: PropTypes.string,
  gutterBottom: PropTypes.bool,
  className: PropTypes.string,
  align: PropTypes.string,
  color: PropTypes.string,
  weight: PropTypes.string,
  shade: PropTypes.number,
  params: PropTypes.instanceOf(Object),
};

Translate.defaultProps = {
  variant: "",
  gutterBottom: false,
  className: "",
  align: "left",
  color: "neutral",
  params: {},
  shade: 500,
};

export default Translate;
