import React, { useState, useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";

import { saveAuthToken, hasAuthToken, deleteAuthToken } from "clever-react/utils/auth";

import { getJWT } from "api/auth";
import PageLoader from "components/common/PageLoader";
import NoLoginPage from "pages/errors/unauthorized";

const ProtectedRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [errorLogin, setErrorLogin] = useState(false);

  useEffect(() => {
    const hmac = searchParams.get("hmac");
    const hasToken = hasAuthToken();

    if (!hmac) {
      setErrorLogin(!hasToken);
      setIsLoading(false);
    } else {
      getJWT(hmac).then(response => {
        searchParams.delete("hmac");
        setSearchParams(searchParams);

        if (!response || !response.authToken) {
          deleteAuthToken();

          setErrorLogin(true);
          setIsLoading(false);
        } else {
          saveAuthToken(response.authToken);

          setErrorLogin(false);
          setIsLoading(false);
        }
      });
    }
  }, [searchParams, setSearchParams]);

  if (isLoading) return <PageLoader fullscreen />;

  if (errorLogin) return <NoLoginPage />;

  return <Outlet />;
};

export default ProtectedRoute;