import React from "react";

import Button from "clever-react/ui/Button";
import Grid from "clever-react/ui/Grid";

import makeStyles from "clever-react/ui/styles/makeStyles";
import Warning from "clever-react/ui/Icon/Warning";
import GeneralLogo from "components/common/GeneralLogo";

import Translate from "components/common/Translate";
import LocalImage from "components/common/LocalImage";

const getClasses = makeStyles(({ cleverUI: { spacing, breakpoints } }) => ({
  root: { ...spacing["mb-6"], textAlign: "center" },
  logo: { ...spacing["mt-8"], ...spacing["mb-4"], maxHeight: "70px", [breakpoints.down("sm")]: { width: "100%" } },
  image: { ...spacing["my-7"], maxWidth: "600px", height: "auto" },
  icon: { ...spacing["mr-2"], ...spacing["mb-0"], verticalAlign: "middle", alignSelf: "center" },
  alert: { ...spacing["mb-4"], display: "inline-flex", flexWrap: "nowrap", justifyContent: "center" },
}));

// pageView("Error Request");

const ErrorPage = () => {
  const classes = getClasses();

  const shopType = "shop";

  const reloadPage = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      const keys = Object.keys(caches);
      for (let i = 0; i < keys.length; i += 1) {
        caches.delete(keys[i]);
      }
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  return (
    <Grid container justify="center" alignItems="center" className={classes.root}>
      <Grid item xs={10}>
        <GeneralLogo type={shopType} className={classes.logo} />
      </Grid>

      <Grid item xs={11} md={10} lg={9}>
        <Translate
          code="errors.500.title"
          variant="h1"
          gutterBottom
          align="center"
          shade={900}
        />
      </Grid>

      <Grid item xs={11} md={10} lg={9}>
        <Grid container alignItems="center" className={classes.alert}>
          <Grid item>
            <Warning color="warning" className={classes.icon} />
          </Grid>

          <Grid item>
            <Translate code="errors.500.description" variant="body3" align="left" shade={900} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={11} md={10} lg={9}>
        <LocalImage src="/waiting/illustration-car.svg" className={classes.image} />
      </Grid>

      <Grid item xs={11} md={10} lg={9}>
        <Button onClick={reloadPage}>
          <Translate code="errors.500.button" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
