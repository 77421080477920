import React from "react";
import { Provider, ErrorBoundary } from "@rollbar/react";

const config = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: "code_version_002",
      },
    },
  },
  captureIp: "anonymize",
  hostBlockList: ["localhost"],
};

function RollbarProvider({ children, errorPage }) {
  return (
    <Provider config={config}>
      <ErrorBoundary fallbackUI={errorPage}>{children}</ErrorBoundary>
    </Provider>
  );
}

export default RollbarProvider;
