import React from "react";

import Flex from "clever-react/ui/CssFlex";
import Typography from "clever-react/ui/Typography";
import Link from "clever-react/ui/Link";
import LoadingClever from "clever-react/ui/LoadingClever";
import makeStyles from "clever-react/ui/styles/makeStyles";
import Button from "clever-react/ui/next/Button";

const getClasses = makeStyles(({ cleverUI: { spaces, colors, typography } }) => ({
  container: {
    padding: spaces.md,
    backgroundColor: colors.neutral[0],
    boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: spaces.lg,
  },
  actionButton: {
    padding: "6px 8px",
    minHeight: "36px",
  },
  actionDisabledButton: {
    fontFamily: typography.font1,
    fontSize: 16,
    textDecoration: "underline",
  }
}));

const ProgressSnackbar = (key, props) => {
  const classes = getClasses();
  const { title, description, buttonText, onButtonClick, isLoading, disabledStyle } = props;

  return (
    <div key={key} className={classes.container}>
      <Flex direction="column">
        {!disabledStyle && (
          <Typography variant="title2" shade={400}>
            {title}
          </Typography>
        )}

        <Typography variant="small" shade={disabledStyle ? 400 : 900} weight="bold">
          {description}
        </Typography>
      </Flex>

      {isLoading && (
        <LoadingClever size={50} />
      )}

      {!isLoading && !disabledStyle && (
        <Button onClick={onButtonClick} className={classes.actionButton}>
          {buttonText}
        </Button>
      )}

      {!isLoading && disabledStyle && (
        <Link onClick={onButtonClick} className={classes.actionDisabledButton}>
          {buttonText}
        </Link>
      )}
    </div>
  );
};

export default ProgressSnackbar;